// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.equipmentInventoryPage {
  font-weight: bold;
  font-size: 150%;
  margin:0 auto;
  width: 70%;
  height:70vh;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  align-content: flex-start;
}

.itemClass {
  position: relative;
  margin:auto;
  margin-top:5%;
  font-size:150%;
  width: 30%;
  height:10%;
  text-align: center;
  justify-content: center;
  align-items: center;
  color:white;
  border: 2px white solid;  
  display: flex;
  border-radius: 5px;
}

.itemClass::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 100%; 
  background-color: #ababab67; 
  transition: width 0.3s ease; 
  transform-origin: left; 
}

.itemClass:hover::before {
  width: 100%;
}

@media screen and (max-width: 529px){
  
}

@media screen and (max-width: 767px) and (min-width: 530px){

}

@media screen and (max-width: 1023px) and (min-width: 768px) {
  
}
`, "",{"version":3,"sources":["webpack://./src/css/equipmentInventory/equipmentInventory.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,eAAe;EACf,aAAa;EACb,UAAU;EACV,WAAW;EACX,gBAAgB;EAChB,aAAa;EACb,eAAe;EACf,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,cAAc;EACd,UAAU;EACV,UAAU;EACV,kBAAkB;EAClB,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,uBAAuB;EACvB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,QAAQ;EACR,YAAY;EACZ,2BAA2B;EAC3B,2BAA2B;EAC3B,sBAAsB;AACxB;;AAEA;EACE,WAAW;AACb;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA","sourcesContent":[".equipmentInventoryPage {\n  font-weight: bold;\n  font-size: 150%;\n  margin:0 auto;\n  width: 70%;\n  height:70vh;\n  overflow-y: auto;\n  display: flex;\n  flex-wrap: wrap;\n  text-align: center;\n  align-content: flex-start;\n}\n\n.itemClass {\n  position: relative;\n  margin:auto;\n  margin-top:5%;\n  font-size:150%;\n  width: 30%;\n  height:10%;\n  text-align: center;\n  justify-content: center;\n  align-items: center;\n  color:white;\n  border: 2px white solid;  \n  display: flex;\n  border-radius: 5px;\n}\n\n.itemClass::before {\n  content: \"\";\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  width: 0;\n  height: 100%; \n  background-color: #ababab67; \n  transition: width 0.3s ease; \n  transform-origin: left; \n}\n\n.itemClass:hover::before {\n  width: 100%;\n}\n\n@media screen and (max-width: 529px){\n  \n}\n\n@media screen and (max-width: 767px) and (min-width: 530px){\n\n}\n\n@media screen and (max-width: 1023px) and (min-width: 768px) {\n  \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
