// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Loading.css */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loading-spinner {
  border: 8px solid rgba(142, 142, 142, 0.3);
  border-top: 8px solid #ffffff;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.itemClassLoading {
  width: 30px;
  height: 30px;
  border: 5px solid rgba(142, 142, 142, 0.3);
  border-top: 5px solid #ffffff;
}

.itemLoading {
  top:20%;
  border: 10px solid rgba(142, 142, 142, 0.3);
  border-top: 10px solid #ffffff;
  position: absolute;
  z-index: 100;
}
`, "",{"version":3,"sources":["webpack://./src/css/loading.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,0CAA0C;EAC1C,6BAA6B;EAC7B,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,kCAAkC;AACpC;;AAEA;EACE,KAAK,uBAAuB,EAAE;EAC9B,OAAO,yBAAyB,EAAE;AACpC;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,0CAA0C;EAC1C,6BAA6B;AAC/B;;AAEA;EACE,OAAO;EACP,2CAA2C;EAC3C,8BAA8B;EAC9B,kBAAkB;EAClB,YAAY;AACd","sourcesContent":["/* Loading.css */\n.loading-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n}\n\n.loading-spinner {\n  border: 8px solid rgba(142, 142, 142, 0.3);\n  border-top: 8px solid #ffffff;\n  border-radius: 50%;\n  width: 80px;\n  height: 80px;\n  animation: spin 1s linear infinite;\n}\n\n@keyframes spin {\n  0% { transform: rotate(0deg); }\n  100% { transform: rotate(360deg); }\n}\n\n.itemClassLoading {\n  width: 30px;\n  height: 30px;\n  border: 5px solid rgba(142, 142, 142, 0.3);\n  border-top: 5px solid #ffffff;\n}\n\n.itemLoading {\n  top:20%;\n  border: 10px solid rgba(142, 142, 142, 0.3);\n  border-top: 10px solid #ffffff;\n  position: absolute;\n  z-index: 100;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
