// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-weight: bold;
}

.alertMessageShort {
  font-size: larger;
  padding:0;
  margin-top:1%;
}


.red {
  color:rgb(190, 42, 42);
}

.green {
  color: rgb(22, 206, 22);
}

.orange {
  color: rgb(216, 144, 48);
}

.absolute {
  position: absolute;
}

.bottom {
  bottom: 5%;
}

.left {
  left: 5%;
}

.medium {
  font-size: medium;
}

.x-large {
  font-size: x-large;
}

.no-space {
  padding: 0;
  margin: 0;
}

@media screen and (max-width:767px) {
  .top {
    top:10%;
  }
  .bottom {
    bottom:3px;
  }
}`, "",{"version":3,"sources":["webpack://./src/css/alert.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,SAAS;EACT,aAAa;AACf;;;AAGA;EACE,sBAAsB;AACxB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,QAAQ;AACV;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,SAAS;AACX;;AAEA;EACE;IACE,OAAO;EACT;EACA;IACE,UAAU;EACZ;AACF","sourcesContent":["body {\n  font-weight: bold;\n}\n\n.alertMessageShort {\n  font-size: larger;\n  padding:0;\n  margin-top:1%;\n}\n\n\n.red {\n  color:rgb(190, 42, 42);\n}\n\n.green {\n  color: rgb(22, 206, 22);\n}\n\n.orange {\n  color: rgb(216, 144, 48);\n}\n\n.absolute {\n  position: absolute;\n}\n\n.bottom {\n  bottom: 5%;\n}\n\n.left {\n  left: 5%;\n}\n\n.medium {\n  font-size: medium;\n}\n\n.x-large {\n  font-size: x-large;\n}\n\n.no-space {\n  padding: 0;\n  margin: 0;\n}\n\n@media screen and (max-width:767px) {\n  .top {\n    top:10%;\n  }\n  .bottom {\n    bottom:3px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
