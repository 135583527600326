// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adminSelectButton {
  margin: 2%;
  width: 20%;
  height: 30vh;
  font-size: 3vh;
  font-weight: bold;
  border-radius: 1vw;
  color: white;
  text-shadow:black 0.01em 0.05em 0.1em;
  border: white 5px solid;
}
.adminSelectButton:hover {
  color:#b4b8c4
}

.lightgreen {
  background-color: rgb(75, 163, 75);
}
.lightorange {
  background-color: rgb(225, 166, 88);
}
.lightpurple {
  background-color: rgb(173, 125, 233);
}
.lightred {
  background-color: rgb(196, 101, 101);
}
.lightgray {
  background-color: rgb(168, 168, 168);
}

@media screen and (max-width: 767px){
  .adminSelectButton {
    width: 70%;
    height: 7vh;
    border:1px white solid;
  }
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
  .adminSelectButton {
    width: 70%;
    height: 7vh;
    border:1px white solid;
  }
}
`, "",{"version":3,"sources":["webpack://./src/css/adminPage.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,UAAU;EACV,YAAY;EACZ,cAAc;EACd,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,qCAAqC;EACrC,uBAAuB;AACzB;AACA;EACE;AACF;;AAEA;EACE,kCAAkC;AACpC;AACA;EACE,mCAAmC;AACrC;AACA;EACE,oCAAoC;AACtC;AACA;EACE,oCAAoC;AACtC;AACA;EACE,oCAAoC;AACtC;;AAEA;EACE;IACE,UAAU;IACV,WAAW;IACX,sBAAsB;EACxB;AACF;;AAEA;EACE;IACE,UAAU;IACV,WAAW;IACX,sBAAsB;EACxB;AACF","sourcesContent":[".adminSelectButton {\n  margin: 2%;\n  width: 20%;\n  height: 30vh;\n  font-size: 3vh;\n  font-weight: bold;\n  border-radius: 1vw;\n  color: white;\n  text-shadow:black 0.01em 0.05em 0.1em;\n  border: white 5px solid;\n}\n.adminSelectButton:hover {\n  color:#b4b8c4\n}\n\n.lightgreen {\n  background-color: rgb(75, 163, 75);\n}\n.lightorange {\n  background-color: rgb(225, 166, 88);\n}\n.lightpurple {\n  background-color: rgb(173, 125, 233);\n}\n.lightred {\n  background-color: rgb(196, 101, 101);\n}\n.lightgray {\n  background-color: rgb(168, 168, 168);\n}\n\n@media screen and (max-width: 767px){\n  .adminSelectButton {\n    width: 70%;\n    height: 7vh;\n    border:1px white solid;\n  }\n}\n\n@media screen and (max-width: 1023px) and (min-width: 768px) {\n  .adminSelectButton {\n    width: 70%;\n    height: 7vh;\n    border:1px white solid;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
