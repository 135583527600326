// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adminSettingsPage {
  position: absolute;
  width: 100%;
  text-align: center;
}

.userPasswordSection {
  font-size: 30px;
  margin: 0 auto;
  position: relative;
  width: 60%;
  font-weight: bold;
  color: white;
  border-radius: 10px;
}

.passwordSection,
.inputSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; 
  color: white;
  text-align: left;
  margin: 10px 0;
}

.submit {
  margin-top: 1%;
  margin-bottom: 1vh;
  width: 8%;
}

.alertSection {
  width: 100%;
  min-height: 50px;
  text-align: center;
}


@media screen and (max-width:767px) {
  .submit {
    width: 20%;
  }
}`, "",{"version":3,"sources":["webpack://./src/css/adminSettings.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,kBAAkB;EAClB,UAAU;EACV,iBAAiB;EACjB,YAAY;EACZ,mBAAmB;AACrB;;AAEA;;EAEE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;EACZ,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,kBAAkB;AACpB;;;AAGA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".adminSettingsPage {\n  position: absolute;\n  width: 100%;\n  text-align: center;\n}\n\n.userPasswordSection {\n  font-size: 30px;\n  margin: 0 auto;\n  position: relative;\n  width: 60%;\n  font-weight: bold;\n  color: white;\n  border-radius: 10px;\n}\n\n.passwordSection,\n.inputSection {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center; \n  color: white;\n  text-align: left;\n  margin: 10px 0;\n}\n\n.submit {\n  margin-top: 1%;\n  margin-bottom: 1vh;\n  width: 8%;\n}\n\n.alertSection {\n  width: 100%;\n  min-height: 50px;\n  text-align: center;\n}\n\n\n@media screen and (max-width:767px) {\n  .submit {\n    width: 20%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
